@import "constants";
@import "fonts";
@import "buttons";

body {
  background-color: transparent;
  font-family: 'Troi-Regular', Fallback, sans-serif;
  overflow-x: hidden;
}

.centered {
  height: auto;
  margin: 0 auto;
  position: relative;
}

button {
  background: none;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  width: 100%;
}

.troiLogoBlack {
  width: 48px;
  height: 50px;
  background-image: url("/assets/images/logo-black.svg");
  object-fit: scale-down;
  background-repeat: no-repeat;
  background-size: contain;
}

.pointer {
  cursor: pointer;
}

.appBarBottom {
  display: none;
}
