:root {
  --main-bg-color-main: #4fadf9;
  --main-bg-color-lighter-main: #9CD1FB;
  --main-bg-color-darker-main: #2B5D99;
  --main-bg-color-main-hover: #448fd0;
  --main-bg-color-darker-main-hover: #254f84;
  --main-bg-color-white: white;
}

.red {
  --main-bg-color-main: #e74c3c;
  --main-bg-color-lighter-main: #ec9d95 ;
  --main-bg-color-darker-main: #cc4334;
  --main-bg-color-main-hover: #c73c2e;
  --main-bg-color-darker-main-hover: #cf4334;
}

.orange {
  --main-bg-color-main: #e67e22;
  --main-bg-color-lighter-main: #fbad50;
  --main-bg-color-darker-main: #e66900;
  --main-bg-color-main-hover: #e66c04;
  --main-bg-color-darker-main-hover: #e6600f;
}

.green {
  --main-bg-color-main: #27ae60;
  --main-bg-color-lighter-main: #7ce09c;
  --main-bg-color-darker-main: #20884a;
  --main-bg-color-main-hover: #248c4a;
  --main-bg-color-darker-main-hover: #208449;
}

.grey {
  --main-bg-color-main: #7f8c8d;
  --main-bg-color-lighter-main: #c4cdce;
  --main-bg-color-darker-main: #606d6e;
  --main-bg-color-main-hover: #6e7a7b;
  --main-bg-color-darker-main-hover: #657071;
}